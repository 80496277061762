import React, {useState} from 'react';

const Footer = () => {
  return (
    <div className='bg-[#0a192f] text-gray-300 px-4 py-3'>
        <p>© {(new Date().getFullYear())} Ahnaf Mahmud</p>
    </div>
  )
}

export default Footer