import CloneInVSCode from '../assets/projects/vscode.webp'
import WSASideloader from '../assets/projects/wsasideloader.webp'
import SubManager from '../assets/projects/submanager.webp'
import WSASystemControl from '../assets/projects/wsasystemcontrol.webp'
import HomepageApp from '../assets/projects/homepage.webp'

export const data=[
    {
        id:1,
        name:"SubManager",
        details:"An iOS/macOS/visionOS app to help keep track of your subscriptions",
        image:SubManager,
        github:"https://submanager.app",
    },
    {
        id:2,
        name:"Homepage",
        details:"Safari extension to load any website upon opening a new tab",
        image:HomepageApp,
        github:"https://github.com/infinitepower18/Homepage-MobileSafari",
    },
    {
        id:3,
        name:"WSA Sideloader",
        details:"An APK installer for Windows Subsystem for Android",
        image:WSASideloader,
        github:"https://github.com/infinitepower18/WSA-Sideloader",
    },
    {
        id:4,
        name:"WSA System Control",
        details:"Monitor WSA status and turn WSA on/off from the system tray",
        image:WSASystemControl,
        github:"https://github.com/infinitepower18/WSA-SystemControl",
    },
    {
        id:5,
        name:"Clone in VS Code",
        details:"Browser extension to clone any repo in VS Code with just one click",
        image:CloneInVSCode,
        github:"https://github.com/infinitepower18/clone-in-vscode",
    }
]